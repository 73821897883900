// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-angebote-js": () => import("./../src/pages/angebote.js" /* webpackChunkName: "component---src-pages-angebote-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-veranstaltungen-js": () => import("./../src/pages/news-veranstaltungen.js" /* webpackChunkName: "component---src-pages-news-veranstaltungen-js" */),
  "component---src-pages-service-infos-js": () => import("./../src/pages/service-infos.js" /* webpackChunkName: "component---src-pages-service-infos-js" */),
  "component---src-templates-gallery-js": () => import("./../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

